import { Box } from '@wh-components/core/Box/Box'
import React, { FunctionComponent } from 'react'
import { Employee, OpeningHours, OrganisationService } from '@bbx/ad-in/organisation/types/DealerProfile'
import { Divider } from '@wh-components/core/Divider/Divider'
import { Text } from '@wh-components/core/Text/Text'
import Check from '@wh-components/icons/Check'
import { DayOfWeek, DayOfWeekGerman, DayOfWeekNumber } from '@bbx/common/types/ad-detail/OrganisationDetails'
import dayjs from 'dayjs'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { AspectRatioBox } from '@wh-components/core/AspectRatioBox/AspectRatioBox'
import ServicesIcon from './icons/services.svg'
import ClockIcon from './icons/clock.svg'
import HeartIcon from './icons/heart.svg'
import TeamIcon from './icons/team.svg'
import EmployeePlaceholderIcon from './icons/employeePlaceholder.svg'
import { filterXSS } from 'xss'

const organisationUnits: Record<string, string> = {
    SHOP: 'Verkauf',
    GARAGE: 'Werkstatt',
}

export interface ContactData {
    openingHours: Record<string, Record<string, OpeningHours[]>>
    services: Record<string, OrganisationService[]>
    employees: Employee[]
    companyDescription: string | undefined
}
export const ContactTab = (props: { contactData: ContactData }) => {
    const companyDescription = sanitizeCompanyDescription(props.contactData.companyDescription)

    return (
        <Box maxWidth={768} padding={{ phone: 'm', tablet: 0 }}>
            {Object.keys(props.contactData.openingHours).length > 0 && (
                <Box>
                    <Divider orientation="horizontal" marginVertical="s" />
                    <Box paddingVertical="l">
                        <SectionHeader imageUrl={ClockIcon} text="Öffnungszeiten" />
                        <OpeningHoursSection hours={props.contactData.openingHours} />
                    </Box>
                </Box>
            )}
            {Object.keys(props.contactData.services).length > 0 && (
                <Box>
                    <Divider orientation="horizontal" marginVertical="s" />
                    <Box paddingVertical="l">
                        <SectionHeader imageUrl={ServicesIcon} text="Services" />
                        <ServicesSection services={props.contactData.services} />
                    </Box>
                </Box>
            )}

            {props.contactData.companyDescription && (
                <Box>
                    <Divider orientation="horizontal" marginVertical="s" />
                    <Box paddingVertical="l">
                        <SectionHeader imageUrl={HeartIcon} text="Über uns" />
                        <Box dangerouslySetInnerHTML={{ __html: companyDescription }} />
                    </Box>
                </Box>
            )}

            {props.contactData.employees.length > 0 && (
                <Box>
                    <Divider orientation="horizontal" marginVertical="s" />
                    <Box paddingVertical="l">
                        <SectionHeader imageUrl={TeamIcon} text="Team" />
                        <EmployeesSection employees={props.contactData.employees} />
                    </Box>
                </Box>
            )}
        </Box>
    )
}

const sanitizeCompanyDescription = (text?: string | null): string => {
    if (!text) {
        return ''
    }

    // remove non-breaking spaces to avoid layout blowout on 320px width
    return filterXSS(text.replace('&nbsp;', ' '), {
        whiteList: {
            p: [],
            strong: [],
            br: [],
            em: [],
            a: ['href', 'target'],
        },
        stripIgnoreTag: true,
    })
}

const SectionHeader = (props: { text: string; imageUrl: string }) => {
    return (
        <Box display="flex" gap="s" marginBottom="m" height={30}>
            <ResponsiveImage src={props.imageUrl} />
            <Box display="flex" alignItems="center" height="100%">
                <Text fontSize="l" fontWeight="bold" color="palette.panther">
                    {props.text}
                </Text>
            </Box>
        </Box>
    )
}

export const EmployeesSection = (props: { employees: Employee[] }) => {
    return (
        <Box display="flex" flexWrap="wrap" gap="m">
            {props.employees.map((employee, index) => (
                <EmployeeBox key={`emp-${index}`} employee={employee} />
            ))}
        </Box>
    )
}
export const OpeningHoursSection = (props: { hours: Record<string, Record<string, OpeningHours[]>> }) => {
    return (
        <Box display="flex" flexDirection={{ phone: 'column', tablet: 'row' }} gap={{ phone: 's', tablet: 'l' }}>
            {Object.keys(props.hours).map((unit, index) => (
                <OpeningHourBlock key={`openingHoursBlock-${index}`} title={organisationUnits[unit]} hours={props.hours[unit]} />
            ))}
        </Box>
    )
}

export const OpeningHourBlock = (props: { title: string; hours: Record<string, OpeningHours[]> }) => {
    const currentDay = dayjs(Date.now()).day()
    return (
        <Box flex={1}>
            <Box marginBottom="s">
                <Text fontSize="m" fontWeight="bold" color="palette.racoon">
                    {props.title}
                </Text>
            </Box>
            {Object.keys(props.hours).map((day) => {
                return (
                    <Box display="flex" key={day} color={currentDay === DayOfWeekNumber[day as DayOfWeek] ? 'palette.primary.main' : ''}>
                        <Box width={25} marginRight="m">
                            <Text>{DayOfWeekGerman[day as DayOfWeek].substr(0, 2)}</Text>
                        </Box>
                        {props.hours[day].map((hours, index) => (
                            <OpeningHour key={index} hourIndex={index} hours={hours} />
                        ))}
                    </Box>
                )
            })}
        </Box>
    )
}

export const OpeningHour: FunctionComponent<{ hourIndex: number; hours: OpeningHours }> = (props) => {
    return (
        <Text>
            {!!props.hourIndex && <Text color="palette.koala">&nbsp;| </Text>}
            {props.hours.timeFrom} - {props.hours.timeTo}
        </Text>
    )
}

export const ServicesSection = (props: { services: Record<string, OrganisationService[]> }) => {
    return (
        <Box display="flex" flexWrap="wrap" gap="m">
            {Object.keys(props.services).map((orgService, index) => (
                <ServiceBlock key={`serviceBlock-${index}`} title={orgService} orgServices={props.services[orgService]} />
            ))}
        </Box>
    )
}

export const ServiceBlock = (props: { title: string; orgServices: OrganisationService[] }) => {
    return (
        <Box width={282}>
            <Box marginBottom="s">
                <Text fontSize="m" fontWeight="bold" color="palette.racoon">
                    {props.title}
                </Text>
            </Box>
            <>
                {props.orgServices.map((service) => (
                    <Box key={service.serviceId} display="flex" gap="s" marginBottom="s">
                        <Check size={20} color="palette.primary.main" />
                        {service.serviceName}
                    </Box>
                ))}
            </>
        </Box>
    )
}

export const EmployeeBox = (props: { employee: Employee }) => {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" backgroundColor="palette.polarbear" padding="l" width={376}>
            <Box>
                <Box marginBottom={2}>
                    <Text fontSize="l" fontWeight="bold" color="palette.racoon">
                        {props.employee.name ?? ''} {props.employee.surname ?? ''}
                    </Text>
                </Box>
                <Box marginBottom={4}>{props.employee.jobDescription}</Box>
                <Box display="flex" marginTop="s" color="palette.primary.main" flexDirection="column">
                    <Text>{props.employee.mobilePhone}</Text>
                    <Text>{props.employee.mobilePhone2}</Text>
                </Box>
            </Box>

            <Box width={78} height={78} backgroundColor="#E5F5FC">
                {props.employee.imageUrl ? (
                    <AspectRatioBox width="78px" ratio={1}>
                        <ResponsiveImage
                            src={props.employee.imageUrl ?? undefined}
                            backgroundColor="palette.babyseal"
                            objectFit="contain"
                        />
                    </AspectRatioBox>
                ) : (
                    <Box display="flex" alignItems="center" height="100%" justifyContent="center">
                        <ResponsiveImage src={EmployeePlaceholderIcon} width={44} />
                    </Box>
                )}
            </Box>
        </Box>
    )
}
