import { Box } from '@wh-components/core/Box/Box'
import React, { useState } from 'react'
import { AutoMotorDealerProfileContainer } from '@bbx/ad-in/organisation/autoMotorDealerProfile/AutoMotorDealerProfileContainer'
import { AutoMotorResultListContainer } from '@bbx/search-journey/sub-domains/search/components/verticals/auto-motor/result-list/AutoMotorResultListContainer'
import { DealerProfileContainer } from '@bbx/ad-in/organisation/components/DealerProfileContainer'
import { useFeatureToggle } from '@wh/common/chapter/components/FeatureToggleProvider/FeatureToggleProvider'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { DealerProfile } from '@bbx/ad-in/organisation/types/DealerProfile'
import { MotorDealerTabs } from '@bbx/ad-in/organisation/components/MotorDealerTabs'
import { AdvertSummaryList } from '@bbx/common/types/ad-detail/AdvertSummary'

type Props = {
    dealerAds: AdvertSummaryList
    searchResult: SearchResult
    dealerProfile: DealerProfile
}
export const DealerProfilePageContent = (props: Props) => {
    const enableDealerProfile = useFeatureToggle('enableAutoMotorDealerProfile')
    const [tab, setTab] = useState<'vehicles' | 'contact'>('vehicles')

    return enableDealerProfile ? (
        <Box>
            <AutoMotorDealerProfileContainer tab={tab} setTab={setTab} dealerProfile={props.dealerProfile} dealerAds={props.dealerAds} />
            {tab === 'vehicles' && (
                <AutoMotorResultListContainer searchResult={props.searchResult} pageType="dealer-profile" pageViewEvent="dealer_profile" />
            )}
        </Box>
    ) : (
        <Box>
            <DealerProfileContainer organisation={props.dealerProfile.organisation} />
            <MotorDealerTabs dealerProfile={props.dealerProfile} searchResult={props.searchResult} />
        </Box>
    )
}
