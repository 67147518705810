import React, { FunctionComponent } from 'react'
import { DealerProfile } from '@bbx/ad-in/organisation/types/DealerProfile'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { sanitizeAdDescription } from '@bbx/common/lib/xss'
import { DealerTabs } from '@bbx/ad-in/organisation/components/DealerTabs'
import { AutoMotorResultListContainer } from '@bbx/search-journey/sub-domains/search/components/verticals/auto-motor/result-list/AutoMotorResultListContainer'

export const MotorDealerTabs: FunctionComponent<{
    dealerProfile: DealerProfile
    searchResult: SearchResult
    resultListLabel?: string
}> = ({ dealerProfile, searchResult }) => {
    const companyDescription =
        dealerProfile.organisation.companyDescription && sanitizeAdDescription(dealerProfile.organisation.companyDescription)
    return (
        <DealerTabs
            dealerProfile={dealerProfile}
            companyPhoto={dealerProfile.organisation.companyProfileImageUrls?.large}
            companyDescription={companyDescription}
            resultListContainer={
                <AutoMotorResultListContainer searchResult={searchResult} pageType="dealer-profile" pageViewEvent="dealer_profile" />
            }
        />
    )
}
