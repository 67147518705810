import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { DealerProfile } from '../types/DealerProfile'
import { fetcher } from '@wh/common/chapter/api/fetcher'
import { NextRequest } from '@wh/common/chapter/types/nextJS'
import { verticals } from '@wh/common/chapter/types/verticals'

export const getDealerProfile = (orgId: string, request?: NextRequest): Promise<DealerProfile> => {
    return fetcher<DealerProfile>(`/iad/dealerprofile/${orgId}`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}

export const getDealerProfileBySlug = (orgSlug: string, vertical: verticals, request?: NextRequest): Promise<DealerProfile> => {
    return fetcher<DealerProfile>(`/iad/dealerprofile/slug/${orgSlug}?vertical=${vertical}`, {
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
    })
}
