import { getDealerProfile } from '@bbx/ad-in/organisation/api/dealerProfileApiClient'
import { DealerProfilePageContent } from '@bbx/ad-in/organisation/components/DealerProfilePageContent'
import { DealerProfile } from '@bbx/ad-in/organisation/types/DealerProfile'
import { AdvertSummaryList } from '@bbx/common/types/ad-detail/AdvertSummary'
import { getAlertSearchResult, getSeoSearchResult } from '@bbx/search-journey/common/api/searchApiClient'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { DesktopBreadcrumbs } from '@bbx/search-journey/sub-domains/search/components/common/result-list/DesktopBreadcrumbs/DesktopBreadcrumbs'
import { ErrorPage } from '@wh/common/chapter/components/ErrorPage/ErrorPage'
import type { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { SkyscraperLayout } from '@wh/common/chapter/components/Layouts/SkyscraperLayout'
import { staticRelativeCanonicals } from '@wh/common/chapter/hocs/staticRelativeCanonicals'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { getFeatureTogglesFromCtx } from '@wh/common/chapter/lib/toggles/feature-toggles'
import { concatPathWithQueryParams, getFirstString } from '@wh/common/chapter/lib/urlHelpers'
import { Request } from 'express'
import { IncomingMessage } from 'http'
import { GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from 'next'
import React, { Fragment } from 'react'

export type DealerProfileProps = {
    dealerAds: AdvertSummaryList
    searchResult: SearchResult
    dealerProfile: DealerProfile
}

export const DealerProfilePage: NextPage<DealerProfileProps> & { Layout: typeof Layout } = (props) => {
    if ('is404' in props) {
        return <ErrorPage statusCode={404} />
    } else {
        return (
            <Fragment>
                <DesktopBreadcrumbs breadcrumbs={dealerProfileAutoBreadcrumbs} />
                <DealerProfilePageContent
                    dealerAds={props.dealerAds}
                    searchResult={props.searchResult}
                    dealerProfile={props.dealerProfile}
                />
            </Fragment>
        )
    }
}

const isRedirectEnabled = async (req: Request | IncomingMessage | undefined) => {
    const toggles = await getFeatureTogglesFromCtx('search', req)
    return toggles.enableAutoMotorRedirectToPersonalizedDealerUrl
}

export const dealerProfileGetServerSideProps =
    (type: string) =>
    async (context: GetServerSidePropsContext): Promise<GetServerSidePropsResult<DealerProfileProps>> => {
        const { query, req } = context

        const { orgId: rawOrgId, alertId, ...remainingQuery } = query
        const dealerProfileOrgId = getFirstString(rawOrgId)
        const searchAgentId = getFirstString(alertId)

        if (!dealerProfileOrgId) {
            return { notFound: true }
        }

        try {
            const basePath = `/gebrauchtwagen/gebrauchtwagenhaendler-detail-${type}`
            const dealerAdsQuery = { orgId: dealerProfileOrgId, rows: '30', page: '1' }
            const concatenatedPath = concatPathWithQueryParams(basePath, { ...query })
            const dealerAdsPath = concatPathWithQueryParams(basePath, { ...dealerAdsQuery })
            const dealerAdsPromise = getSeoSearchResult(dealerAdsPath, req)
            const searchResultPromise = searchAgentId ? getAlertSearchResult(query, req) : getSeoSearchResult(concatenatedPath, req)
            const dealerProfilePromise = getDealerProfile(dealerProfileOrgId, req)

            // when quering the initial search, all the ads of an org are fetched.
            // the searchResult changes later when the filters are changed
            // the dealerAds stay true to the same initial search
            const [searchResult, dealerProfile, dealerAds] = await Promise.all([
                searchResultPromise,
                dealerProfilePromise,
                dealerAdsPromise,
            ])

            if (dealerProfile.organisation.slug && (await isRedirectEnabled(req))) {
                // Redirect to the current Personalized Dealer URL if slug of organisation is available
                const redirectUrl = concatPathWithQueryParams(`/iad/haendler/${dealerProfile.organisation.slug}/${type}`, {
                    alertId,
                    ...remainingQuery,
                })
                return { redirect: { destination: redirectUrl, permanent: true } }
            }

            return { props: { dealerAds: dealerAds.advertSummaryList, searchResult, dealerProfile } }
        } catch (error) {
            const apiError = wrapInApiErrorIfNecessary(error)

            if (apiError.statusCode && apiError.statusCode >= 400 && apiError.statusCode <= 499) {
                return { notFound: true }
            }

            throw apiError
        }
    }

export const getServerSideProps = dealerProfileGetServerSideProps('auto')
DealerProfilePage.Layout = SkyscraperLayout

export default DealerProfilePage

export const dealerProfileAutoBreadcrumbs = [
    { displayName: 'Startseite', seoUrl: staticRelativeCanonicals.Home },
    { displayName: 'Auto & Motor', seoUrl: staticRelativeCanonicals.MotorHome },
    { displayName: 'Händlerprofil', seoUrl: '' },
]
