import React from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { DealerProfile, Organisation } from '@bbx/ad-in/organisation/types/DealerProfile'
import { ContactData, ContactTab } from '@bbx/ad-in/organisation/autoMotorDealerProfile/ContactTab'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import Call from '@wh-components/icons/Call'
import PinIcon from './icons/pin.svg'
import WebIcon from './icons/web.svg'
import { prependHttpIfMissing } from '@wh/common/chapter/lib/urlHelpers'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import DefaultBanner from './icons/defaultBanner.png'
import { BubbleRadioGroup, BubbleRadioGroupChildRadioProps } from '@wh-components/core/FormElements/BubbleRadio/BubbleRadioGroup'
import { sanitizeAdDescription } from '@bbx/common/lib/xss'
import { HighlightedAds } from '@bbx/ad-in/organisation/autoMotorDealerProfile/HighlightedAds'
import { AdvertSummary, AdvertSummaryList } from '@bbx/common/types/ad-detail/AdvertSummary'

type Tab = 'vehicles' | 'contact'

const publishedAttribute = 'PUBLISHED_String'
const upsellingAttributes = ['RESULT_LIST_TOPAD', 'RESULT_LIST_STYLE2', 'UPSELLING_RESULT_LIST_AD_IN_MOTION']
const MIN_ADS_TO_SHOW = 3

interface DealerProfileContainerProps {
    dealerAds: AdvertSummaryList
    dealerProfile: DealerProfile
    tab: Tab
    setTab: (tab: Tab) => void
}

export const AutoMotorDealerProfileContainer = ({ dealerAds, dealerProfile, tab, setTab }: DealerProfileContainerProps) => {
    const companyLogo = dealerProfile.organisation.mainLogoUrls?.large
    const contactData = getContactData(dealerProfile)
    const sortedAds = getSortedAds(dealerAds)
    const hasEnoughAds = sortedAds.advertSummary.length >= MIN_ADS_TO_SHOW

    return (
        <Box position="relative">
            <Box
                height={{ phone: 80, tablet: 100 }}
                marginLeft={{ phone: 'm', tablet: '0' }}
                marginRight={{ phone: 'm', tablet: '0' }}
                marginTop={{ phone: 'm', tablet: '0' }}
            >
                <ResponsiveImage src={DefaultBanner} width="100%" height="100%" objectFit="cover" borderTopRadius="m" />
            </Box>
            <Box
                top={36}
                right={36}
                position="absolute"
                width={{ phone: 100, tablet: 150 }}
                height={{ phone: 100, tablet: 150 }}
                backgroundColor="palette.babyseal"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="m"
            >
                <Box width={{ phone: 86, tablet: 130 }} height={{ phone: 86, tablet: 130 }} borderRadius="m">
                    {companyLogo && (
                        <Box display="flex" alignContent="center" alignSelf="center">
                            <ResponsiveImage
                                src={companyLogo}
                                objectFit="contain"
                                cssWidth="100%"
                                cssHeight="auto"
                                aspectRatio={1}
                                borderRadius="l"
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            <Box paddingHorizontal="l" paddingTop="l" gap="0">
                <Radios tab={tab} setTab={setTab} hasContactInformation={hasContactInformation(contactData)} />
                <CompanyInfo organisation={dealerProfile.organisation} />
                {hasEnoughAds && tab === 'vehicles' && (
                    <Box marginBottom="xl">
                        <HighlightedAds ads={sortedAds} />
                    </Box>
                )}
                {tab === 'contact' && <ContactTab contactData={contactData} />}
            </Box>
        </Box>
    )
}

const isUpsellingAd = (ad: AdvertSummary): boolean => {
    return (
        ad.attributes.attribute.filter((attribute) => {
            return upsellingAttributes.includes(attribute.name)
        }).length > 0
    )
}

export const getSortedAds = (advertSummaryList: AdvertSummaryList): AdvertSummaryList => {
    const sortedByDate = getSortedAdsByPublishedDate(advertSummaryList)
    const sortedByUpselling = getSortedAdsByRelevance(sortedByDate)

    return sortedByUpselling
}
export const getSortedAdsByPublishedDate = (advertSummaryList: AdvertSummaryList): AdvertSummaryList => {
    const sorted = advertSummaryList.advertSummary.sort((ad1, ad2) => {
        const publishedDate1 = getPublishedDate(ad1)
        const publishedDate2 = getPublishedDate(ad2)

        if (publishedDate1 > publishedDate2) {
            return -1
        }
        return 1
    })

    return {
        advertSummary: sorted,
    }
}

const getPublishedDate = (ad: AdvertSummary): number => {
    const attribute = ad.attributes.attribute.find((a) => a.name === publishedAttribute)
    if (attribute !== undefined) {
        return Date.parse(attribute.values[0])
    }

    return Date.now()
}
export const getSortedAdsByRelevance = (advertSummaryList: AdvertSummaryList): AdvertSummaryList => {
    const sorted = advertSummaryList.advertSummary.sort((ad1, ad2) => {
        const isUpsellingAd1 = isUpsellingAd(ad1)
        const isUpsellingAd2 = isUpsellingAd(ad2)

        if (isUpsellingAd1 === isUpsellingAd2) {
            return 0
        } else if (isUpsellingAd1) {
            return -1
        }
        return 1
    })

    return {
        advertSummary: sorted,
    }
}

const getContactData = (dealerProfile: DealerProfile) => {
    const sanitizedDescription =
        dealerProfile.organisation.companyDescription && sanitizeAdDescription(dealerProfile.organisation.companyDescription)
    const description = sanitizedDescription?.trim()

    return {
        openingHours: dealerProfile.openingHours,
        services: dealerProfile.services,
        employees: dealerProfile.employees,
        companyDescription: description,
    }
}
const hasContactInformation = (contactData: ContactData) => {
    const hasOpeningHours = Object.keys(contactData.openingHours).length > 0
    const hasServices = Object.keys(contactData.services).length > 0
    const hasDescription = contactData.companyDescription !== undefined
    const hasEmployees = contactData.employees.length > 0

    return hasOpeningHours || hasServices || hasDescription || hasEmployees
}

const getAddress = (organisation: Organisation) => {
    return `${organisation.addressDto.addressStreet} ${organisation.addressDto.addressPostcode} ${organisation.addressDto.addressTown}`
}

const CompanyInfo = (props: { organisation: Organisation }) => {
    const companyUrl = props.organisation?.contactDto.url
    const phone = props.organisation?.contactDto.phone
    const phone2 = props.organisation?.contactDto.phone2

    return (
        <Box paddingTop="l" paddingBottom="xl">
            <Box fontSize="xl" fontWeight="bold" color="palette.panther">
                {props.organisation.displayName}
            </Box>
            <Box paddingTop="s" display="flex" flexDirection={{ phone: 'column', tablet: 'row' }} gap={{ phone: 's', tablet: 'xl' }}>
                <Box display="flex" minWidth={100} gap="s" flexShrink={3}>
                    <ResponsiveImage src={PinIcon} color="palette.primary.main" width="20" height="20" />
                    {getAddress(props.organisation)}
                </Box>
                {companyUrl && (
                    <Box display="flex" color="palette.primary.main" gap="s">
                        <ResponsiveImage src={WebIcon} color="palette.primary.main" width="20" height="20" />
                        <ServerRoutingAnchorLink type="anchor" rel="nofollow" target="_blank" href={prependHttpIfMissing(companyUrl)}>
                            {companyUrl}
                        </ServerRoutingAnchorLink>
                    </Box>
                )}
                <Box display="flex" color="palette.primary.main" gap="s">
                    <Call color="palette.primary.main" size={20} />
                    <Box display="flex" flexDirection="column" minWidth={120}>
                        {phone && (
                            <ServerRoutingAnchorLink type="anchor" href={`tel:${phone}`}>
                                {phone}
                            </ServerRoutingAnchorLink>
                        )}
                        {phone2 && (
                            <ServerRoutingAnchorLink type="anchor" href={`tel:${phone2}`}>
                                {phone2}
                            </ServerRoutingAnchorLink>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const Radios = (props: { tab: Tab; setTab: (tab: Tab) => void; hasContactInformation: boolean }) => {
    const radios: BubbleRadioGroupChildRadioProps[] = [
        {
            id: `radioVehicles`,
            label: 'Fahrzeuge',
            value: 'vehicles',
            testId: 'radioVehiclesTest',
            checked: props.tab === 'vehicles',
            onChange: () => {
                props.setTab('vehicles')
            },
        },
        ...(props.hasContactInformation
            ? [
                  {
                      id: `radioContact`,
                      label: 'Kontakt & Über uns',
                      value: 'contact',
                      testId: 'radioContactTest',
                      checked: props.tab === 'contact',
                      onChange: () => {
                          props.setTab('contact')
                      },
                  },
              ]
            : []),
    ]

    return <BubbleRadioGroup name="radioGroup1" label="" testId="radioGroup1" inline={true} size="medium" comment="" radios={radios} />
}
